// @flow
import React from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading, ExpandableOverflow, Markdown, MediaQuery } from '@riseart/common';
import { RssIcon } from '@riseart/icons';
import { WithLocale } from 'shared_hocs/common/WithLocale';
import { LocalizedConfig } from 'shared_services/riseart/utils/LocalizedConfig';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { LocalizedLink } from 'shared_components/i18n/LocalizedLink';
import { VerticalMenuOverlay } from 'shared_components/common/menu/overlay/Menu';

import { raScreenLg } from '@riseart/antd-provider/dist/website/variables.less';
import {
  blogHeaderCls,
  blogHeaderMenuCls,
  blogHeaderMenuItemActiveCls,
} from 'shared_components/article/category/Header.less';

type Props = {
  title: Node,
  description?: string,
  categories: Array<Object>,
  selectedCategoryId?: string | number,
  children?: Node,
};

/**
 * BlogHeader
 *
 * @param {Props} props
 */
export const BlogHeader = ({
  title,
  description = null,
  categories = [],
  selectedCategoryId = null,
  children = null,
}: Props): Node => {
  return (
    <div className={blogHeaderCls}>
      {title ? (
        <Heading tag="h1" level="1">
          {title}
        </Heading>
      ) : null}
      {categories && categories.length ? (
        <MediaQuery minWidth={raScreenLg}>
          {(isScreenLg) =>
            isScreenLg ? (
              children || (
                <ul className={blogHeaderMenuCls}>
                  {categories.map((category) => (
                    <li key={category.id}>
                      {selectedCategoryId && selectedCategoryId === category.id ? (
                        <span className={blogHeaderMenuItemActiveCls}>{category.name}</span>
                      ) : (
                        <LocalizedLink
                          type="light"
                          assembler={{
                            handler: UrlAssembler.articleCategory,
                            args: [category.uri, category.type],
                          }}
                        >
                          {category.name}
                        </LocalizedLink>
                      )}
                    </li>
                  ))}
                </ul>
              )
            ) : (
              <WithLocale>
                {(locale) => (
                  <VerticalMenuOverlay
                    title={title}
                    items={[
                      ...categories.map(({ name, uri, id, type }) => ({
                        url: UrlAssembler.articleCategory(uri, type, { locale }),
                        label: name,
                        selected: selectedCategoryId === id,
                      })),
                      {
                        url: LocalizedConfig.get('externalUrls.articleRssFeed'),
                        label: (
                          <FormattedMessage id="common.rssFeed">
                            {(label: string) => (
                              <React.Fragment>
                                <RssIcon /> {label}
                              </React.Fragment>
                            )}
                          </FormattedMessage>
                        ),
                        linkProps: {
                          title: '',
                          target: '_blank',
                          external: true,
                        },
                      },
                    ]}
                  />
                )}
              </WithLocale>
            )
          }
        </MediaQuery>
      ) : null}
      {description ? (
        <ExpandableOverflow
          maxHeight={70}
          showMore={<FormattedMessage id="common.show_more" />}
          showLess={<FormattedMessage id="common.show_less" />}
        >
          <Markdown text={description} />
        </ExpandableOverflow>
      ) : null}
    </div>
  );
};
